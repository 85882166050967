import { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import styles from './style.module.scss';
import { buildClassName } from 'utils/build-class-name';
import useSizeClass, { useIsMobile } from 'hooks/use-size-class';
import PlayButtonIcon from 'components/icon/play-button';
import VolumeIcon from 'components/icon/volume-icon';
import MuteIcon from 'components/icon/mute-icon';

type VideoRevealStyles = React.CSSProperties & CustomCSSProperties;
interface CustomCSSProperties {
  '--container-width'?: string;
  '--container-height'?: string;
  '--arch-width'?: string;
  '--arch-height'?: string;
}
const TextContainer = ({ isMobile }:{isMobile: boolean}) => (
  <div className={isMobile? styles['mobile-text'] : styles['default-text']}>
    <h2>We&apos;re <span
      className={styles['header-highlight']} >Built</span> for This</h2>
    <p>We never settle, and we love it that way. Your success drives our passion for pushing the real estate industry beyond its limits.</p>
  </div>
);


const MuteButton = ({ onClick }: {onClick: () => void}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <button className={styles['mute-button']}
      onClick={() => {
        setIsActive(!isActive);
        onClick();
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isActive ? <VolumeIcon fill={isHovered ? '#CECFD3' : 'white'} />
        :
        <MuteIcon fill={isHovered ? '#CECFD3' : 'white'}/>}
    </button>
  );
};

const PlayButton = ({ onClick, isPlaying }: {onClick: () => void; isPlaying: boolean}) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
  }, [isPlaying]);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <button id='playButton' className={styles['play-button']}
      onClick={ () =>onClick()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <PlayButtonIcon foregroundFill={isHovered || isPlaying ? '#0C0F24': 'white'} backgroundFill={isHovered || isPlaying ? 'white' :'#0C0F24'}/>
    </button>
  );
};

const VideoRevealMobile = () => {
  const videoSectionRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const pinContainerRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  const isTablet = useSizeClass({ min: 566, max: 768 });
  const [isPlaying, setIsPlaying] = useState(false);

  const initialContainerWidth = 1440;
  const initialContainerHeight = 761;
  const initialArchWidth = 563;
  const initialArchHeight = 696.198;
  const toggleMute = () => {
    const video = videoRef.current;
    if (video) video.muted = !video.muted;
  };

  return (
    <div
      ref={containerRef}
      className={buildClassName(styles.component, isMobile && styles['mobile-component'])}
      style={{
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        height: isMobile ? '600px' : '750px',
        '--container-width': `${initialContainerWidth}px`,
        '--container-height': `${initialContainerHeight}px`,
        '--arch-width': `${initialArchWidth}px`,
        '--arch-height': `${initialArchHeight}px`,
      } as VideoRevealStyles}
    >
      <div
        ref={pinContainerRef}
        style={{
          backgroundColor: '#0C0F24',
          height: '100%',
        }}
      >
        <TextContainer isMobile={isMobile} />
        <div
          className={styles['svg-wrapper']}
          style={{
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
          }}
          ref={videoSectionRef}
        >
          <PlayButton isPlaying={isPlaying} onClick={() => {
            setIsPlaying(!isPlaying);
            isPlaying? gsap.fromTo('#playButton', {
              opacity: 0,
            }, {
              opacity: 1,
              duration: .25,
            }) :
              gsap.fromTo('#playButton', {
                opacity: 1,
              }, {
                opacity: 0,
                duration: .25,
              });
            !isPlaying ? videoRef.current?.play() : videoRef.current?.pause();
          }} />
          <div
            className='videoWrapper'
            style={{
              objectFit: 'cover',
              transform: 'translateZ(0)',
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'column',
            }}
          >
            <video
              ref={videoRef}
              width="100%"
              autoPlay
              playsInline
              style={{
                objectFit: 'cover',
                transform: 'translateZ(0)',
                height: isTablet ? '65%' : isMobile ? '30%' : '60%',
                minHeight: '280px',
              // these values will change when we have a real video, currently skewing the video height to have roughly the same effect
              }}
              muted
              controls={false}
              loop={true}
            >
              <source src="/assets/video-section1.mp4" type="video/mp4" />
            Your browser doesnt support HTML video.
            </video>
          </div>
          <MuteButton onClick={toggleMute}/>
        </div>
      </div>
    </div>
  );
};

export default VideoRevealMobile;

import React, { useEffect, useState } from 'react';
import Layout from 'components/layout';
import useQueryParams from 'hooks/use-query-params';
import AnimatedCarousel from 'components/animated-carousel';
import { useModalContext } from 'contexts/modal';
import { buildClassName } from 'utils/build-class-name';
import { trackEvent } from 'utils/google-tag-manager';
import styles from '../style.module.scss';
import DEFAULT_LISTING_PARAMS from 'contexts/preferences/listing-params/defaults';
import data from 'components/home-page/internal-links-exp/data.json';
import NotificationBanner from 'components/notification-banner';
import { useUserContext, usePreferencesContext, useThemeContext } from 'contexts';
import { testIds } from 'constants/test-constants';
import { dasherize } from '@zoocasa/node-kit/strings';
import { findLocationByPlaceId } from 'utils/google-maps/geoLocator';
import { checkLocationHasAreaPageWithId, getLocationSlugFromId } from 'data/search-predictions';
import { ModalOpeners } from 'types/modal';
import LoadWhenVisible from 'components/load-when-visible';
import LoadingSkeleton from 'components/loading-skeleton';
import dynamic from 'next/dynamic';
import ExpForSaleSection from '../themed-for-sale-section/exp';
import VideoReveal from 'components/video-reveal';
import VideoRevealMobile from 'components/video-reveal/mobile';
import { useIsDesktop } from 'hooks/use-size-class';

import type { HomePageProps } from '../index';

export default function HomePageExpUS({ isCrawler, isNotificationBannerHidden, availableHomeListings, availableHomeListingsMeta, availableHomeListingsLocation, isExpandedArea }: HomePageProps) {
  const { userLocation, siteLocation } = useUserContext();
  const { lastSearchLocation } = usePreferencesContext();
  const { openModal } = useModalContext();
  const { theme } = useThemeContext();
  const isDesktop = useIsDesktop();

  const [homesForSaleUrl, setHomesForSaleUrl] = useState(`/${siteLocation?.toLowerCase()}-real-estate`);
  const [mapViewUrl, setMapViewUrl] = useState(`/search?latitude=${DEFAULT_LISTING_PARAMS.filter.latitude}&longitude=${DEFAULT_LISTING_PARAMS.filter.longitude}`);
  const [isInternalLinksVisible, setIsInternalLinksVisible] = useState(false);
  const [isKeywordsListVisible, setIsKeywordsListVisible] = useState(false);
  const [isExpAwardsVisible, setIsExpAwardsVisible] = useState(false);
  const [isExpJoinVisible, setIsExpJoinVisible] = useState(false);
  const [isExpContentCardsVisible, setIsExpContentCardsVisible] = useState(false);
  const [isVideoRevealVisible, setIsVideoRevealVisible] = useState(false);
  const [isExpPromotionCardVisible, setIsExpPromotionCardVisible] = useState(false);
  const [ExpAwardCarousel, setExpAwardCarousel] = useState<any>();
  const [ExpJoinCarousel, setExpJoinCarousel] = useState<any>();
  const [ExpContentCards, setExpContentCards] = useState<any>();
  const [ExpPromotionCard, setExpPromotionCard] = useState<any>();
  const [KeywordsList, setKeywordsList] = useState<any>();
  const [InternalLinks, setInternalLinks] = useState<any>();
  const [InternalLinksExp, setInternalLinksExp] = useState<any>();

  const generateUrls = async () => {
    if (lastSearchLocation) {
      let lastSearchLatitude = lastSearchLocation?.data?.position?.latitude || lastSearchLocation?.data?.position?.coordinates[1];
      let lastSearchLongitude = lastSearchLocation?.data?.position?.longitude || lastSearchLocation?.data?.position?.coordinates[0];
      if (!lastSearchLongitude || !lastSearchLongitude) {
        const geoLocation = await findLocationByPlaceId(lastSearchLocation?.locationId || lastSearchLocation?.id);
        lastSearchLatitude = geoLocation?.latitude;
        lastSearchLongitude = geoLocation?.longitude;
      }
      const hasAreaPage = checkLocationHasAreaPageWithId(lastSearchLocation.id);
      if (hasAreaPage) {
        let location = getLocationSlugFromId(lastSearchLocation.id);
        let subLocation = '';
        if (lastSearchLocation.label === 'neighbourhood') {
          const locationArr = lastSearchLocation.description.split(', ');
          subLocation = '/' + dasherize(locationArr[0]);
          location = dasherize((locationArr[1] + ' ' + locationArr[2]).toLowerCase());
        }
        const updatedHomeUrl = `/${location}-real-estate${subLocation}`;
        setHomesForSaleUrl(updatedHomeUrl);
      } else {
        const updatedHomeUrl = `/search?latitude=${lastSearchLatitude}&longitude=${lastSearchLongitude}`;
        setHomesForSaleUrl(updatedHomeUrl);
      }
      setMapViewUrl(`/search?latitude=${lastSearchLatitude}&longitude=${lastSearchLongitude}`);
    } else if (userLocation) {
      const updatedHomeUrl = `/${userLocation.slug}-real-estate`;
      setHomesForSaleUrl(updatedHomeUrl);
      setMapViewUrl(`/search?latitude=${userLocation?.latitude}&longitude=${userLocation?.longitude}`);
    }
  };

  const generateInternalLinks = () => data['exp-us-links'];

  useQueryParams(params => openModal('reset-password', params), ModalOpeners.RESET_PASSWORD_TOKEN, ModalOpeners.EMAIL);
  useQueryParams(params => openModal('confirm-account', params), ModalOpeners.CONFIRMATION_TOKEN, ModalOpeners.USER_AGENT);
  useQueryParams(params => openModal('login-registration', params), ModalOpeners.SIGNIN_EMAIL);
  useQueryParams(params => openModal('login-registration', params), ModalOpeners.WARNING_MESSAGE);
  useQueryParams(params => openModal('notification', { message: params.notification as string }), ModalOpeners.NOTIFICATION);

  useEffect(() => {
    generateUrls();
    trackEvent('UiHomePView');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInternalLinksVisible && !InternalLinks) {
      setInternalLinks(dynamic(import('components/home-page/internal-links')));
      setInternalLinksExp(dynamic(import('components/home-page/internal-links-exp')));
    }
    if (isKeywordsListVisible && !KeywordsList) {
      setKeywordsList(dynamic(import('components/home-page/keywords-list')));
    }
    if (isExpAwardsVisible && !ExpAwardCarousel) {
      setExpAwardCarousel(dynamic(import('components/home-page/exp-components/award-carousel')));
    }
    if (isExpJoinVisible && !ExpJoinCarousel) {
      setExpJoinCarousel(dynamic(import('components/home-page/exp-components/join-exp')));
    }
    if (isExpContentCardsVisible && !ExpContentCards) {
      setExpContentCards(dynamic(import('components/home-page/content-cards')));
    }
    if (isExpPromotionCardVisible && !ExpPromotionCard) {
      setExpPromotionCard(dynamic(import('components/home-page/exp-components/large-promotion-card')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInternalLinksVisible, isKeywordsListVisible, isExpAwardsVisible, isExpJoinVisible, isExpContentCardsVisible, isExpPromotionCardVisible]);

  return (
    <Layout>
      <NotificationBanner label="New Construction Homes are here!" link="/newhomes" isHidden={isNotificationBannerHidden} />
      <AnimatedCarousel/>
      <div className={styles['exp-homes-sale-divider']}/>
      <section className={styles.component}>
        <div className={buildClassName(styles['home-modules'], styles['exp-home'])}>
          <ExpForSaleSection isCrawler={isCrawler} availableHomeListings={availableHomeListings} availableHomeListingsMeta={availableHomeListingsMeta} availableHomeListingsLocation={availableHomeListingsLocation} isExpandedArea={isExpandedArea} homesForSaleUrl={homesForSaleUrl} mapViewUrl={mapViewUrl} />
        </div>
        <section className={styles['home-sections']}>
          <LoadWhenVisible onValueChange={() => setIsExpAwardsVisible(true)}>
            {isExpAwardsVisible && ExpAwardCarousel && <ExpAwardCarousel/>}
          </LoadWhenVisible>
        </section>
        <div className={buildClassName(styles['home-modules'], styles['exp-home'])}>
          <>
            <section className={styles['home-sections']}>
              <LoadWhenVisible onValueChange={() => setIsExpJoinVisible(true)}>
                {isExpJoinVisible && ExpJoinCarousel && <ExpJoinCarousel link={theme.organization.joinSectionUrl}/>}
              </LoadWhenVisible>
            </section>
            <div className={styles['exp-sold-price-divider']}/>
            <section className={styles['home-sections']}>
              <LoadWhenVisible onValueChange={() => setIsExpContentCardsVisible(true)}>
                {isExpContentCardsVisible && ExpContentCards && <ExpContentCards/>}
              </LoadWhenVisible>
            </section>
          </>
          <div className={styles['exp-sold-price-divider']}/>
        </div>
        <LoadWhenVisible onValueChange={() => setIsVideoRevealVisible(true)}>
          {isVideoRevealVisible && isDesktop && <VideoReveal/>}
          {isVideoRevealVisible && !isDesktop && <VideoRevealMobile/>}
        </LoadWhenVisible>
        <section className={styles['home-sections']}>
          <LoadWhenVisible onValueChange={() => setIsExpPromotionCardVisible(true)}>
            {isExpPromotionCardVisible && ExpPromotionCard && <ExpPromotionCard/>}
          </LoadWhenVisible>
        </section>
        <section className={buildClassName(styles['home-sections'], styles['find-by-keyword-exp'])} data-testid={testIds.homeKeywords}>
          <div className={styles.container}>
            <LoadWhenVisible onValueChange={() => setIsKeywordsListVisible(true)}>
              {!isKeywordsListVisible && <LoadingSkeleton/>}
              {isKeywordsListVisible && KeywordsList &&
                <div className={buildClassName(styles['home-block'], styles['exp-remove-btm-padding'])}>
                  <div className={buildClassName(styles.row, styles['find-by-keyword-heading'])} data-testid={testIds.findByKeyword}>
                    <h2>Search by <b>Keywords</b></h2>
                  </div>
                  <div className={styles['keywords-list']}>
                    <KeywordsList/>
                  </div>
                </div>
              }
            </LoadWhenVisible>
          </div>
        </section>
      </section>
      <LoadWhenVisible onValueChange={() => setIsInternalLinksVisible(true)}>
        {!isInternalLinksVisible && <LoadingSkeleton/>}
        {isInternalLinksVisible && InternalLinks &&
          <InternalLinksExp isCrawler={isCrawler} data={generateInternalLinks()}/>}
      </LoadWhenVisible>
    </Layout>
  );
}
